<template>
  <v-card>
    <v-card-title>
      List Patient Profiles
      <v-spacer></v-spacer>
      <!-- clear search/filters -->
      <btn
        label="Reset Search/Filters"
        color="secondary"
        :icon="icons.mdiUndoVariant"
        @click="resetSearchFilters"
      ></btn>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="patients"
      item-key="id"
      :expanded.sync="expanded"
      :class="displayResults"
      :page.sync="pagination.page"
      :items-per-page="pagination.itemsPerPage"
      :sort-by.sync="sort.by"
      :sort-desc.sync="sort.desc"
      hide-default-footer
      show-expand
      single-expand
      class="has-pagination"
      @page-count="pagination.pageCount = $event"
      @click:row="viewPatient"
    >
      <template #top>
        <v-row class="mx-4 mt-2 pb-1">
          <!-- first name -->
          <text-field
            v-model="search.first_name"
            :append-icon="icons.mdiMagnify"
            label="First Name"
            class="col-sm-4 px-1"
          ></text-field>
          <!-- last name -->
          <text-field
            v-model="search.last_name"
            :append-icon="icons.mdiMagnify"
            label="Last Name"
            class="col-sm-4 px-1"
          ></text-field>
          <!-- date of birth -->
          <date-input
            v-model="search.dob_us"
            :append-icon="icons.mdiMagnify"
            label="Date of Birth"
            class="col-sm-4 px-1"
            rules="dob"
          ></date-input>
        </v-row>

        <v-row class="mx-4 mt-0 pb-1">
          <!-- facility filter -->
          <select-box
            v-model="search.facility"
            :items="facilities"
            label="Facility"
            class="col-sm-6 px-1"
          >
          </select-box>
          <!-- gender filter -->
          <select-box
            v-model="search.gender"
            :items="genders"
            label="Gender"
            class="col-sm-3 px-1"
          >
          </select-box>
          <!-- last 4 of ssn -->
          <text-field
            v-model="search.ssn_last_4"
            :append-icon="icons.mdiMagnify"
            label="SSN"
            mask="####"
            placeholder="####"
            class="col-sm-3 px-1"
          ></text-field>
        </v-row>
      </template>

      <!-- first name -->
      <template #item.first_name="{ item }">
        <div class="table-no-wrap">
          {{ item.first_name }}
        </div>
      </template>

      <!-- last name -->
      <template #item.last_name="{ item }">
        <div class="table-no-wrap">
          {{ item.last_name }}
        </div>
      </template>

      <!-- dob -->
      <template #item.dob="{ item }">
        {{ $date(item.dob).format('MM/DD/YYYY') }}
      </template>

      <!-- facility -->
      <template #item.place_of_service_id="{ item }">
        <div class="table-no-wrap">
          {{ $store.getters['facilities/getById'](item.place_of_service_id).title }}
        </div>
      </template>

      <!-- actions -->
      <template #item.actions="{item}">
        <v-menu
          transition="slide-y-transition"
          offset-y
          left
        >
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ attrs['aria-expanded'] === 'true' ? icons.mdiClose : icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="viewPatient(item)">
              <icon-value :icon="icons.mdiAccountEye">
                View Patient Profile
              </icon-value>
            </v-list-item>

            <v-list-item @click="editPatient(item)">
              <icon-value :icon="icons.mdiAccountEdit">
                Edit Patient Profile
              </icon-value>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item
              :disabled="item.is_deceased"
              @click="createEncounter(item)"
            >
              <icon-value
                :disabled="item.is_deceased"
                :icon="icons.mdiAccountMultiplePlus"
              >
                Create Encounter
              </icon-value>
            </v-list-item>

            <v-list-item
              :disabled="item.is_deceased"
              @click="createQuickEntry(item)"
            >
              <icon-value
                :disabled="item.is_deceased"
                :icon="icons.mdiClockPlus"
              >
                Create Quick Entry
              </icon-value>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <!-- expanded data -->
      <template #expanded-item="{ item }">
        <td :colspan="headers.length">
          <div>
            <label>Full Name:</label>
            <patient-name :patient="item"></patient-name>
          </div>
          <div v-if="item.is_deceased">
            <label>Deceased Date:</label>
            {{ $date(item.date_deceased).format('MM/DD/YYYY') }}
          </div>
          <div v-if="item.is_deceased">
            <label>Cause of Death:</label>
            {{ item.cause_of_death }}
          </div>
          <div v-if="!item.is_deceased">
            <label>Age:</label>
            {{ $custom.getAgeDescription(item.dob) }}
          </div>
          <div>
            <label>SSN:</label>
            {{ item.ssn_last_4 }}
          </div>
          <div v-if="item.city && item.state_abbr">
            <label>Address:</label>
            {{ item.address_1 }},
            <span v-if="item.address_2">{{ item.address_2 }},</span>
            {{ item.city }},
            {{ item.state_abbr }}
            {{ item.zip }}
          </div>
          <div v-if="item.no_insurance">
            <label>Insurance:</label>
            Not Insured
          </div>
          <div>
            <label>Facility:</label>
            {{ $store.getters['facilities/getById'](item.place_of_service_id).title }}
          </div>
          <div>
            <label>Patient ID:</label>
            {{ item.id }}
          </div>
        </td>
      </template>

      <!-- footer -->
      <template #footer>
        <div class="v-data-table__wrapper search-message mb-5">
          <table>
            <tbody>
              <tr class="v-data-table__empty-wrapper">
                <td>
                  Select search filters to find patient profiles
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </v-data-table>

    <!-- pagination -->
    <pagination
      :page.sync="pagination.page"
      :items-per-page.sync="pagination.itemsPerPage"
      :page-count="pagination.pageCount"
      :class="displayResults"
    ></pagination>

    <v-card-actions>
      <v-spacer></v-spacer>
      <!-- create patient profile -->
      <btn
        label="Create Patient Profile"
        :icon="icons.mdiAccountPlus"
        @click="createPatientProfile"
      ></btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import '@/components/patients/primitives'
import {
  mdiAccountEdit,
  mdiAccountMultiplePlus,
  mdiAccountPlus,
  mdiMagnify,
  mdiUndoVariant,
  mdiCloseThick,
  mdiDotsVertical,
  mdiClose,
  mdiClockPlus,
  mdiAccountEye,
} from '@mdi/js'
import { mapGetters, mapState } from 'vuex'

const initialSearch = {
  first_name: null,
  last_name: null,
  dob: null,
  dob_us: null,
  facility: null,
  gender: null,
  ssn_last_4: null,
}

const initialState = {
  by: 'last_name',
  desc: false,
}

export default {
  data() {
    return {
      search: { ...initialSearch },
      sort: { ...initialState },
      icons: {
        mdiAccountEdit,
        mdiAccountPlus,
        mdiAccountMultiplePlus,
        mdiMagnify,
        mdiUndoVariant,
        mdiCloseThick,
        mdiDotsVertical,
        mdiClose,
        mdiClockPlus,
        mdiAccountEye,
      },
      headers: [
        {
          text: 'First',
          value: 'first_name',
          filter: value => !this.search.first_name || this.$custom.toLowerCase(value).indexOf(this.search.first_name.trim().toLowerCase()) === 0,
        },
        {
          text: 'Last',
          value: 'last_name',
          filter: value => !this.search.last_name || this.$custom.toLowerCase(value).indexOf(this.search.last_name.trim().toLowerCase()) === 0,
        },
        {
          text: 'DOB',
          value: 'dob',
          filter: value => !this.search.dob || value === this.search.dob,
        },
        {
          text: 'SSN',
          value: 'ssn_last_4',
          align: 'hide',
          filter: value => !this.search.ssn_last_4 || value === this.search.ssn_last_4,
        },
        {
          text: 'Gender',
          value: 'gender',
          align: 'small',
          filter: value => !this.search.gender || value === this.search.gender,
        },
        {
          text: 'Facility',
          value: 'place_of_service_id',
          align: 'facility',
          filter: value => !this.search.facility || value === this.search.facility,
        },
        {
          text: '', value: 'actions', align: 'xsmall', sortable: false,
        },
      ],
      expanded: [],
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
      },
      debounceTimer: null,
    }
  },
  computed: {
    ...mapGetters('encounters', ['genders']),
    ...mapGetters('auth', ['facilities']),
    ...mapState('patients', { patients: 'items' }),

    displayResults() {
      return (
        this.search.facility
        || this.search.gender
        || this.search.first_name?.length > 1
        || this.search.last_name?.length > 1
        || this.search.dob?.length === 10
        || this.search.ssn_last_4?.length === 4
          ? 'search-message-hide'
          : 'body-hide'
      )
    },
  },
  watch: {
    'search.dob_us': {
      handler() {
        this.search.dob = this.search.dob_us ? this.$date(this.search.dob_us).format('YYYY-MM-DD') : null
      },
    },
    search: {
      deep: true,
      handler() { this.updateQuery() },
    },
    pagination: {
      deep: true,
      handler() { this.updateQuery() },
    },
    sort: {
      deep: true,
      handler() { this.updateQuery() },
    },
  },
  mounted() {
    // Query string sets search filters & pagination
    if (!this.$custom.isObjectEmpty(this.$route.query)) {
      this.search = {
        ...initialSearch,
        ...this.$route.query,
        facility: Number(this.$route.query.facility),
      }
      this.sort.by = this.$route.query.by
      this.sort.desc = this.$custom.strToBoolNull(this.$route.query.desc)
      if (this.$route.query.itemsPerPage) {
        this.pagination.itemsPerPage = Number(this.$route.query.itemsPerPage)
      }
      if (this.$route.query.page) {
        this.$nextTick(() => {
          this.pagination.page = Number(this.$route.query.page)
        })
      }
    }
  },
  methods: {
    updateQuery() {
      // Debounce
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        // Replace the query
        const query = { ...this.search, ...this.sort, ...this.pagination }
        Object.keys(query).forEach(key => { if (query[key] === null || typeof query[key] === 'undefined') query[key] = '' })
        window.history.replaceState({}, null, `${this.$route.path}?${new URLSearchParams(query).toString()}`)
        this.$store.commit('route/updateQuery', query)
      }, 250)
    },
    resetSearchFilters() {
      this.search = { ...initialSearch }
      this.sort = { ...initialState }
    },
    viewPatient(item) {
      // Load the complete data for the related patient and encounters.
      this.$store.dispatch('patients/loadPatientData', item.id)
      this.$store.dispatch('encounters/loadEncounterData', item.id)

      this.$router.push({ name: 'view-patient', query: { id: item.id } })
    },
    editPatient(item) {
      // Load the complete data for the related patient and encounters.
      this.$store.dispatch('patients/loadPatientData', item.id)
      this.$store.dispatch('encounters/loadEncounterData', item.id)

      this.$router.push({ name: 'edit-patient', query: { id: item.id } })
    },
    createEncounter(item) {
      // Load the complete data for the related patient and encounters.
      this.$store.dispatch('patients/loadPatientData', item.id)
      this.$store.dispatch('encounters/loadEncounterData', item.id)

      this.$router.push({ name: 'create-encounter', query: { id: item.id } })
    },
    createQuickEntry(item) {
      // Load the complete data for the related patient and encounters.
      this.$store.dispatch('patients/loadPatientData', item.id)
      this.$store.dispatch('encounters/loadEncounterData',  item.id)

      this.$router.push({ name: 'create-encounter', query: { id: item.id, quickEntry: true } })
    },
    createPatientProfile() {
      this.$router.push({ name: 'add-patient' })
    },
    closeAction() {
      // Do nothing
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .body-hide .v-data-table__wrapper:not(.search-message) tbody,
  .search-message-hide .search-message,
  .body-hide.pagination {
    display: none;
  }
}
</style>
